<template>
    <bottom-popup ref="popup" class="Unlock-popup pc" v-bind="$attrs">
        <div class="Unlock">
            <div class="close" @click="close"></div>
            <div class="title"><span></span>Unlock Subsequent Episodes</div>
            <div class="Package" :class="{ act: current.id == item.id }" v-for="(item, i) in list" :key="i" @click="itemClick(item)">
                <div class="price">
                    ${{ item.priceShow }} <span>{{ item.priceDay }}</span>
                </div>
                <div class="info">
                    {{ item.subDesc }}
                </div>
                <div class="tips">Auto renewal - Cancel anytime</div>
                <div class="discount" v-if="item.discountRatio">{{ item.discountRatio }}</div>
            </div>
            <div class="payMent" @click="CardPayment">Pay Now</div>
        </div>
    </bottom-popup>
</template>

<script>
import BottomPopup from "../BottomPopup.vue";
export default {
    name: "Unlock",
    components: {
        BottomPopup
    },
    inject: ["openWPay"],
    data() {
        return {
            current: {},
            list: []
        };
    },
    created() {
        this.$http.get("api/subscribe/config/list", {}).then(async (res) => {
            if (res.success && res.data && res.data.length > 0) {
                this.list = res.data;
                this.list.forEach((item) => {
                    item.priceShow = this.priceFm(item.subPrice);
                    if (item.discountRatio) {
                        this.current = item;
                    }
                    if (item.subDesc) {
                        item.day = item.subDesc.replace(/[^\d]/g, "");
                    }
                });
            }
        });
    },
    methods: {
        open() {
            this.$refs.popup.openPopup();
        },
        close() {
            this.$refs.popup.closePopup();
        },
        itemClick(item) {
            this.current = item;
        },
        weedlyDiscount() {
            this.list.forEach((item) => {
                if (item.id == 1) {
                    item.price = "$17.09";
                    item.priceDetails = "$2.4 / day";
                    item.discount = "10% off Weedly";
                }
            });
        },
        CardPayment() {
            this.$store.commit("SETSETMENU", this.current);
            sessionStorage.setItem("setMenu", JSON.stringify(this.current));
            this.close();
            this.openWPay();
        },
        priceFm(p) {
            return String(p).substring(0, String(p).length - 2) + "." + String(p).substring(String(p).length - 2);
        }
    }
};
</script>

<style lang="scss" scoped>
.Unlock-popup {
    ::v-deep .popup-content {
        background: url("./images/UnlockBg.png") 0 0 / 100% no-repeat, #000;
    }
    .Unlock {
        position: relative;
        height: 535px;
        width: 100%;
        overflow: hidden;
        .close {
            position: absolute;
            right: 0;
            top: 0;
            background: url("../../../../images/close.png") 0 0 / 24px no-repeat;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .title {
            height: 29px;
            margin-top: 61px;
            margin-bottom: 44px;
            font-family: Crimson Text;
            font-size: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                display: inline-block;
                width: 26px;
                height: 26px;
                background: url("../../../../images/lock.png") 0 0 / cover no-repeat;
                margin-right: 9px;
            }
        }
        .Package {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 89px;
            padding: 7px 0;
            background: url("./images/itemBg.png") 50% 50% / cover no-repeat;
            border: 3px solid transparent;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            & + .Package {
                margin-top: 16px;
            }
            &.act {
                background: url("./images/itemBgAct.png") 50% 50% / cover no-repeat;
                border: 3px solid #ecb671;
            }
            .price {
                margin: 0 16px 6px;
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                height: 22px;
                span {
                    display: inline-block;
                    font-size: 11px;
                    margin-left: 10px;
                }
            }
            .info {
                margin: 0px 11px 4px 11px;
                display: inline-block;
                height: 22px;
                line-height: 22px;
                background: #ffd094;
                color: #3f1f25;
                font-size: 10px;
                padding: 0 5px;
            }
            .tips {
                font-size: 10px;
                height: 14px;
                line-height: 14px;
                margin: 0 16px 0px;
                color: #ffffff80;
            }
            .discount {
                background: #b42efb;
                font-size: 12px;
                height: 24px;
                line-height: 24px;
                padding: 0 6px;
                position: absolute;
                right: 0;
                top: 0;
                border-radius: 0 7px 0 7px;
            }
        }
        .payMent {
            background: #d45252;
            height: 44px;
            border-radius: 8px;
            text-align: center;
            line-height: 44px;
            font-size: 16px;
            margin-top: 32px;
        }
    }
    &.pc {
        ::v-deep .popup-content {
            width: 536px;

            padding: 16px 24px 32px;
            .title {
                margin-top: 26px;
                margin-bottom: 32px;
                font-size: 24px;
                height: 31px;
            }
            .Unlock {
                height: auto;
            }
            .Package {
                height: 117px;
                padding: 13px 0;
                cursor: pointer;
                .price {
                    margin-bottom: 8px;
                    span {
                        font-size: 14px;
                    }
                }
                .info {
                    height: 30px;
                    line-height: 30px;
                    border-radius: 2px;
                    font-size: 14px;
                    margin-bottom: 8px;
                }
                .tips {
                    height: 17px;
                    line-height: 17px;
                    font-size: 12px;
                }
            }
            .payMent {
                margin-top: 32px;
                cursor: pointer;
            }
        }
    }
}
</style>
