<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <Unlock ref="Unlock" :noContentTop="true" />
        <WPay ref="WPay" :noContentTop="true" />
        <!-- <Coupon ref="Coupon" /> -->
        <!-- <Congratulations ref="Congratulations" /> -->
        <Login ref="Login" />
    </div>
</template>
<script>
import Unlock from "./components/Unlock/index.vue";
import WPay from "./components/Pay.vue";
// import Coupon from "./components/Coupon/index.vue";
// import Congratulations from "./components/Congratulations/index.vue";
import Login from "./components/Logins/index.vue";
export default {
    components: {
        Unlock,
        WPay,
        // Coupon,
        // Congratulations,
        Login
    },
    provide() {
        return {
            openUnlock: () => {
                this.$refs.Unlock.open();
            },
            openWPay: () => {
                this.$refs.WPay.open();
            },
            // openCoupon: () => {
            //     this.$refs.Coupon.open();
            // },
            // openCongratulations: (openlg) => {
            //     this.$refs.Congratulations.open(openlg);
            // },
            openLogin: () => {
                this.$refs.Login.open();
            }
        };
    },
    async mounted() {
        // this.$refs.Coupon.open();
        // this.$http
        //     .post("api/stripe/create-subscription", {
        //         email: "11@11.com",
        //         paymentMethodId: "string",
        //         priceId: "string"
        //     })
        //     .then((res) => {
        //         console.log(res);
        //     });
    }
};
</script>
<style lang="scss">
html {
    font-size: 100px;
}
body,
html {
    padding: 0;
    margin: 0;
    background: #000;
}
.web {
    overflow: hidden;
}
#app {
    font-family: "微软雅黑";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
p {
    margin: 0;
}
* {
    box-sizing: border-box;
    user-select: none;
}

.webCover {
    border-radius: 5px;
    background: url("../../images/empty-seat.png") center center / 20% no-repeat, rgba(255, 255, 255, 0.2);
    position: relative;
    &.play:hover {
        &::after {
            position: absolute;
            z-index: 10;
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            background: url("./images/play.png") center center / 60px 60px no-repeat, rgba(0, 0, 0, 0.5);
        }
    }
}
.mint-toast {
    z-index: 10001 !important;
}
.mint-indicator-wrapper,
.mint-indicator-mask {
    z-index: 10002 !important;
}
</style>
