import Vue from "vue";
import mApp from "./views/mobile/App.vue";
import wApp from "./views/web/App.vue";
import router from "./router";
import store from "./store";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import globalMixin from "./mixins/globalMixin";
Vue.mixin(globalMixin);
Vue.config.devtools = true;
Vue.config.productionTip = false;
import request from "./request";
Vue.prototype.$http = request;

import { Toast, Indicator } from "mint-ui";
import "mint-ui/lib/style.css";
Vue.prototype.$Toast = Toast;
Vue.prototype.$Indicator = Indicator;

let deviceId = localStorage.getItem("deviceId");
localStorage.removeItem("token");
if (!deviceId) {
    const result = await FingerprintJS.load({
        screenResolution: true,
        availableScreenResolution: true,
        colorDepth: true,
        // pixelRatio: true,
        hardwareConcurrency: true,
        timezone: true,
        language: true,
        platform: true,
        userAgent: true,
        cpuClass: true,
        deviceMemory: true
    });
    const visitorId = await result.get();
    deviceId = visitorId.visitorId;
    localStorage.setItem("deviceId", deviceId);
}

function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isHarmonyOS = /harmonyos/i.test(userAgent);

    // 判断设备是否为手机
    if (isHarmonyOS) {
        return /mobile/i.test(userAgent) || window.innerWidth <= 768; // 可根据需要调整宽度阈值
    }

    // 检测其他常见移动设备
    return /android|iphone|ipad|ipod|windows phone|blackberry|iemobile|mobile/i.test(userAgent);
}

Vue.prototype.$isMobile = isMobile;
if (!isMobile()) {
    document.body.classList.add("web");
}

Vue.directive("InfiniteScroll", {
    bind(el, binding) {
        // 获取绑定的加载方法
        const callback = binding.value;
        if (typeof callback !== "function") {
            console.warn("InfiniteScroll: binding value must be a function.");
            return;
        }

        // 防抖处理，防止短时间内多次触发
        let debounceTimeout = null;

        const handleScroll = () => {
            if (debounceTimeout) return;

            debounceTimeout = setTimeout(() => {
                debounceTimeout = null;

                const scrollContainer = el.scrollParent || el;
                const { scrollTop, scrollHeight, clientHeight } = scrollContainer;

                // 判断是否接近底部
                if (scrollHeight - scrollTop <= clientHeight + 10) {
                    callback();
                }
            }, 200); // 防抖时间
        };

        // 监听滚动事件
        el.addEventListener("scroll", handleScroll);

        // 保存到 el 上，便于解绑时使用
        el._handleScroll = handleScroll;
    },

    unbind(el) {
        // 移除事件监听
        el.removeEventListener("scroll", el._handleScroll);
        delete el._handleScroll;
    }
});

import { checkAndLoginApi } from "@/api";
await checkAndLoginApi();

import "amfe-flexible";
new Vue({
    router,
    store,
    render: (h) => h(isMobile() ? mApp : wApp)
}).$mount("#app");
